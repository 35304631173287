import {
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonRadioGroup,
  IonAlert,
  IonListHeader,
  IonLabel,
  IonRadio,
  useIonViewWillEnter,
} from "@ionic/react";
import react, { useState } from "react";
import { useHistory } from "react-router";
import Header from "../components/Header";
import "./Basic_style.scss";
import Cookies from "universal-cookie";
import { APP_DEMO, BASE_URL } from "../BaseUrl";
import axios from "axios";
import Toast from "../components/InfoToast";

const cookies = new Cookies();

const RecordReturn: React.FC = () => {
  const [selectedReason, setSelectedReason] = useState<string>("");
  const [trackingInputDetails, setTrackingInputDetails] = useState<any>("");
  const [orderItemDetail, setOrderItemDetail] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [showToast, setShowToast] = useState<any>(false);
  const [ean, setEan] = useState<any>('');
  const [showAlert, setShowAlert] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>('');
  const [alertButtons, setAlertButtons] = useState<any>([]);

  const history = useHistory();

  useIonViewWillEnter(async () => {
    setSelectedReason('');
    let tracking_input_details = cookies.get("retour_tracking_input_details");
    let tracking_code_detail = cookies.get("retour_tracking_code_detail");
    tracking_code_detail = tracking_code_detail.orders;
    setTrackingInputDetails(tracking_input_details);

    tracking_code_detail.map((item: any, index: any) => {
      if (item.articleNumber == tracking_input_details.article_number) {
        setOrderItemDetail(item);
        setEan(item.articleNumber);
      }
    });
  });

  const infoAlert = (message: any, type: any) => {
    setAlertMessage(message);
    if(type == 'info alert'){
      setAlertButtons([
        {
          text: "ok",
          role: "cancel",
          handler: () => {
          },
        },
      ]);
    }
    setShowAlert(true);
  }

  const handleClick = (reason: any) => {
    setSelectedReason(reason);
    let tracking_input_details = trackingInputDetails;
    tracking_input_details.reason = reason.toString();
    setTrackingInputDetails(tracking_input_details);

    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    cookies.set("retour_tracking_input_details", tracking_input_details, {
      path: "/",
      expires: date,
      sameSite: "lax",
    });

    if(APP_DEMO){
      history.push("/return-status");
    }else{
      let url = BASE_URL + "update/orderdetails";
    axios
      .post(url, {
        sorderid: parseInt(orderItemDetail.sorderid),
        sordersdetailsid: parseInt(orderItemDetail.sordersdetailsid),
        digi1ordersdetailsattributesquantity: parseInt(tracking_input_details.quantity),
        digi1ordersdetailsattributesreturnreason: parseInt(tracking_input_details.reason),
        retouretrackingid: tracking_input_details.tracking_code,
        articleNumber: tracking_input_details.article_number,
        orderstatus: 36,
        positionstatus: 4
      })
      .then((res) => {
        if (res.status == 201) {
          history.push("/return-status");
        }else{
          infoAlert("Something went wrong", "info alert");
          // setMessage('Something went wrong');
          // setShowToast(true);
        }
      })
      .catch((err) => {
        console.log(err);
        infoAlert("Something went wrong", "info alert");
        // setMessage('Something went wrong');
        // setShowToast(true);
        //history.push("/return-status");
      });
    }
  };

  const toastSetting = (setting: any) => {
    setShowToast(setting);
  };

  return (
    <IonPage>
      <Header title="Retourenartikel erfassen" />
      <IonContent fullscreen className="wrapper">
        <IonGrid className="ps-15">
          <IonRow className="list-row">
            <IonCol>
              <h3>Artikel EAN:</h3>
              <h4>{trackingInputDetails.article_name}</h4>
              <h4>{trackingInputDetails.article_number}</h4>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid class="grid-section">
          <div className="mb-20">
            <h3>Retourengrund</h3>
          </div>
          <IonRadioGroup
            value={selectedReason}
            onClick={(e: any) => handleClick(e.target.value)}
          >
            <IonRow className="ion-radio-row">
              {[...Array(11)].map((item: any, index: any) => {
                return (
                  <IonCol key={index} className="ion-radio-col" size="4">
                    <IonRadio
                      mode="md"
                      className="custom-radio"
                      value={index + 1}
                    />
                    <IonLabel className="custom-label">{index + 1}</IonLabel>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonRadioGroup>
        </IonGrid>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          message={alertMessage}
          buttons={alertButtons}
        />
        <Toast
          message={message}
          showToast={showToast}
          toastSetting={(setting: any) => toastSetting(setting)}
        />
      </IonContent>
    </IonPage>
  );
};

export default RecordReturn;
