import {
  IonContent,
  IonButtons,
  IonIcon,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonAlert,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from "@ionic/react";
import { arrowForwardOutline, arrowBackOutline } from "ionicons/icons";
import { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Header from "../components/Header";
import Toast from "../components/InfoToast";
import "./Basic_style.scss";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const RecordIncoming: React.FC = () => {
  const inputRef = useRef<any>(null);

  const [trackingCode, setTrackingCode] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [showToast, setShowToast] = useState<any>(false);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>('');
  const [alertButtons, setAlertButtons] = useState<any>([]);

  useIonViewWillEnter(() => {
    setTrackingCode('');
    setTimeout(() => inputRef.current.setFocus(), 100);
  });

  const trackingCodeAddition = async () => {
    if (trackingCode == "" || trackingCode == " ") {
      // setMessage("Invalid tracking code");
      // setShowToast(true);
      playBeep('error');
      infoAlert("Invalid tracking code", "info alert");
      setTrackingCode("");
    } else if(!/^[0-9]+$/.test(trackingCode)) {
      playBeep('error');
      infoAlert("Format Tracking ID is not correct.", "info alert");
      setTrackingCode("");
    } else {
      let tracking_codes = cookies.get("retour_tracking_codes")
        ? cookies.get("retour_tracking_codes")
        : [];

      for await (let tracking of tracking_codes) {
        if (tracking == trackingCode) {
          // setMessage("Tracking code already exists");
          // setShowToast(true);
          infoAlert("Tracking code already exists", "info alert");
          playBeep('error');
          return;
        }
      }
      tracking_codes.push(trackingCode);
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      cookies.set("retour_tracking_codes", tracking_codes, {
        path: "/",
        expires: date,
        sameSite: "lax",
      });
      setTrackingCode("");
      playBeep('scan');
      history.push("/record-list");
    }
  }

  const handleEnter = async (e: any) => {
    if (e.charCode == "13") {
      trackingCodeAddition();
    }
  };

  useEffect(() => {
    //setTimeout(() => inputRef.current.setFocus(), 100);
  });

  const history = useHistory();

  const playBeep = (type:any) => {
    let beep = type === 'scan'? new Audio(require("../assets/beeps/scan-beep.mp3")) : new Audio(require("../assets/beeps/error-beep.mp3"));
    beep.play();
  }

  const handleClick = async () => {
    trackingCodeAddition();
  };

  const toastSetting = (setting: any) => {
    setShowToast(setting);
  };

  const infoAlert = (message: any, type: any) => {
    setAlertMessage(message);
    if(type == 'info alert'){
      setAlertButtons([
        {
          text: "ok",
          role: "cancel",
          handler: () => {
            setTimeout(() => inputRef.current.setFocus(), 100);
          },
        },
      ]);
    }
    setShowAlert(true);
  }

  return (
    <IonPage>
      <Header title="Retoureneingang erfassen" />
      <IonContent fullscreen className="wrapper">
        <div className="heading-portion">
          <h3>Bitte Tracking Code/ID scannen.</h3>
        </div>
        <div className="detail_with_image">
          <IonImg src={require("../assets/images/detail.jpg")} />
        </div>
        <div className="id-input-div">
          <IonItem lines="none" className="record-items">
            <IonInput
              onIonChange={(e) => setTrackingCode(e.detail.value)}
              onKeyPress={(event) => handleEnter(event)}
              ref={(ref) => (inputRef.current = ref)}
              value={trackingCode}
              className="input_focus"
              placeholder="ID manuell eingeben"
            />
            <IonIcon
              className="arrow-forward-icon"
              icon={arrowForwardOutline}
              onClick={handleClick}
            />
          </IonItem>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          message={alertMessage}
          buttons={alertButtons}
        />
        <Toast
          message={message}
          showToast={showToast}
          toastSetting={(setting: any) => toastSetting(setting)}
        />
      </IonContent>
    </IonPage>
  );
};

export default RecordIncoming;