import {
  IonContent,
  IonButtons,
  IonIcon,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonAlert,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { arrowForwardOutline, arrowBackOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import "./Basic_style.scss";
import Cookies from "universal-cookie";
import Toast from "../components/InfoToast";
import { APP_DEMO } from "../BaseUrl";

const cookies = new Cookies();

const OrderArticle: React.FC = () => {
  const inputRef = useRef<any>(null);

  const [articleEAN, setArticleEAN] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [showToast, setShowToast] = useState<any>(false);
  const [trackingCodeDetail, setTrackingCodeDetail] = useState<any>("");
  const [trackingCode, setTrackingCode] = useState<any>('');
  const [orderNumber, setOrderNumber] = useState<any>('');
  const [showAlert, setShowAlert] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>('');
  const [alertButtons, setAlertButtons] = useState<any>([]);
  const [articleName, setArticleName] = useState<any>('');

  useEffect(() => {});

  useIonViewWillEnter(() => {
    setArticleEAN("");
    setTimeout(() => inputRef.current.setFocus(), 100);
    let tracking_code_detail = cookies.get("retour_tracking_code_detail");
    console.log(tracking_code_detail);
    setTrackingCodeDetail(tracking_code_detail);
    let tracking_code = cookies.get('retour_tracking_id');
    setTrackingCode(tracking_code);
    let order_number = cookies.get('retour_order_number');
    setOrderNumber(order_number);
  });

  const history = useHistory();

  const infoAlert = (message: any, type: any) => {
    setAlertMessage(message);
    if(type == 'info alert'){
      setAlertButtons([
        {
          text: "ok",
          role: "cancel",
          handler: () => {
            setTimeout(() => inputRef.current.setFocus(), 100);
          },
        },
      ]);
    }
    setShowAlert(true);
  }

  const playBeep = (type:any) => {
    let beep = type === 'scan'? new Audio(require("../assets/beeps/scan-beep.mp3")) : new Audio(require("../assets/beeps/error-beep.mp3"));
    beep.play();
  }

  const articleEANValidation = async () => {
    if (articleEAN == "" || articleEAN == " ") {
      playBeep('error');
      infoAlert("Invalid article EAN", "info alert");
      setArticleEAN("");
      // setMessage("Invalid article EAN");
      // setShowToast(true);
    } else {
      let found: boolean = false;
      let article_n;
      for await (let item of trackingCodeDetail.orders) {
        if(APP_DEMO){
          if (item.articleNumber == "4058509473333") {
            article_n = item.articleName;
            await setArticleName(item.articleName);
            found = true;
          }
        }else{
          if (item.articleNumber == articleEAN) {
            article_n = item.articleName;
            await setArticleName(item.articleName);
            found = true;
          }
        }
      }
      if (found) {
        let tracking_input_details = cookies.get(
          "retour_tracking_input_details"
        );
        tracking_input_details.article_number = articleEAN;
        tracking_input_details.article_name = article_n;
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        cookies.set("retour_tracking_input_details", tracking_input_details, {
          path: "/",
          expires: date,
          sameSite: "lax",
        });
        playBeep('scan');
        history.push("/Quantity");
      } else {
        playBeep('error');
        infoAlert("Article EAN not found on this order", "info alert");
        setArticleEAN("");
        // setMessage("Article EAN not found on this order");
        // setShowToast(true);
      }
    }
  }

  const handleEnter = async (e: any) => {
    if (e.charCode == "13") {
      articleEANValidation();
    }
  };

  const handleClick = async () => {
    articleEANValidation();
  };

  const toastSetting = (setting: any) => {
    setShowToast(setting);
  };

  return (
    <IonPage>
      <Header title="Retoureneingang erfassen" />
      <IonContent fullscreen className="wrapper">
        <div className="heading-portion">
          <h3>Bestellnummer:</h3>
          <h4>{orderNumber}</h4>
        </div>
        <div className="heading-portion">
          <h3>Bitte Artikel EAN Code scannen.</h3>
        </div>
        <div className="detail_with_image">
          <IonImg src={require("../assets/images/product.jpg")} />
        </div>
        <div className="id-input-div">
          <IonItem lines="none" className="record-items">
            {/* <IonInput  ref={(ref) => inputRef.current = ref} placeholder='Artikel EAN manuell eingeben' /> */}
            <IonInput
              onIonChange={(e) => setArticleEAN(e.detail.value)}
              onKeyPress={(event) => handleEnter(event)}
              ref={(ref) => (inputRef.current = ref)}
              value={articleEAN}
              className="input_focus"
              placeholder="Artikel EAN manuell eingeben"
            />
            <IonIcon
              className="arrow-forward-icon"
              icon={arrowForwardOutline}
              onClick={handleClick}
            />
          </IonItem>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          message={alertMessage}
          buttons={alertButtons}
        />
        <Toast
          message={message}
          showToast={showToast}
          toastSetting={(setting: any) => toastSetting(setting)}
        />
      </IonContent>
    </IonPage>
  );
};

export default OrderArticle;
