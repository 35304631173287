import { IonContent, IonGrid, IonRow, IonCol,IonImg, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import './Basic_style.scss';
import './Home.scss';
import Header from '../components/Header';

const Home: React.FC = () => {
  const history=useHistory();
  const handleClick=()=>{
    history.push("/record-incoming");
  }
  const recordReturnClick=()=>{
    history.push("/record-return-article");
  }
  return (
    <IonPage className="home_close">
      <Header title="Retoure Bereich wählen"/>
      <IonContent fullscreen className="wrapper overFlow-hidden">
      <div className="row">
        <div className="column"  onClick={handleClick}>
       <IonImg src={require('../assets/images/file-tray-outline.png')} />
       <p>Retoureneingang erfassen</p>
        </div>
        <div className="column2" onClick={recordReturnClick}>
       <IonImg src={require('../assets/images/cube-outline.png')} /> 
       <p>Retourenartikel erfassen</p>
        </div>
      </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
