export let trackingCodeDetails = {
  orders: [
    {
      sordersdetailsid: 8,
      sorderid: 30,
      articleId: "78459",
      articleName: "Tankini-Oberteil Malaika schwarz-weiß 38 C",
      articleNumber: "4058509473333",
      status: "1",
      quantity: "10",
    },
    {
      sordersdetailsid: 9,
      sorderid: 30,
      articleId: "78402",
      articleName: "Bikini-Set Kauai hawaiian denim 38 E",
      articleNumber: "123",
      status: "0",
      quantity: "7",
    },
    {
      sordersdetailsid: 10,
      sorderid: 30,
      articleId: "78430",
      articleName: "Bikini-Hose Zigzag black-white-toffee 38",
      articleNumber: "4056592747553",
      status: "0",
      quantity: "1",
    },
  ],
};
